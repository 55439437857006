import { Icon } from "components/lib"
import React from "react"

export function Button(props) {
  const colors = {
    blue: "flex items-center gap-2 text-white bg-gradient-to-r from-[#84baf8] to-[#2f34c0] hover:from-[#2f34c0] hover:to-[#84baf8] focus:ring-4 focus:outline-none focus:ring-[#84baf8]/50 shadow-lg shadow-[#2f34c0]/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",

    green:
      "flex items-center gap-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:from-green-500 hover:to-green-400 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",

    teal: "flex items-center gap-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:from-teal-500 hover:to-teal-400 focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",

    red: "flex items-center gap-2 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:from-red-500 hover:to-red-400 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",

    orange:
      "flex items-center gap-2 text-white bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 hover:from-orange-500 hover:to-orange-400 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:focus:ring-orange-800 shadow-lg shadow-orange-500/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",

    inst: "flex items-center gap-2 text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 shadow-lg shadow-pink-500/50 font-medium rounded-full text-sm px-5 py-2 text-center transition-all duration-300 ease-in-out",
  }
  return (
    <button
      type="button"
      className={colors[props.color || "blue"] + " " + props.className}
      onClick={(e) => props.action && props.action(e)}
    >
      {props.text}
      {props.icon && (
        <div className={`${props.iconLoading && "animate-spin"}`}>
          <Icon image={props.icon} color={props.iconColor} />
        </div>
      )}
    </button>
  )
}
