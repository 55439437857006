const Style = {
  base: "relative p-3 md:p-4 x-1 bg-white rounded-sm",
  header: "relative pb-5 mb-5 border-dotted border-slate-100 border-b",
  title: "float:left m-0 text-lg font-semibold",
  center: "mx-auto",
  loading: "h-60",
  shadow: "drop-shadow-sm",
  noPadding: "!p-0",
  restrictWidth: "max-w-md",
  last: "mb-0",
}

export default Style
