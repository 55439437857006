import { lazy } from "react"
import { s } from "components/lib"

const MainPage = lazy(() => import("views/home"))
const Survey = lazy(() => import("views/survey"))

const Routes = [
  {
    path: "/",
    view: s(MainPage),
    layout: "app",
    title: "Talk&Call",
  },
  {
    path: "/application",
    view: s(Survey),
    layout: "app",
    title: "Talk&Call Careers",
  },
]

export default Routes
