import { useContext, useState } from 'react';

const CreateLeadModal = ({ handleCreateLead }) => {

  const [data, setData] = useState({
    name: '',
    phone: '',
    utm_source: 'request'
  });

  return (
    <div className='grid grid-cols-2 gap-3'>
      <p className='font-bold'>Имя<span className='text-red-500'>*</span></p>
      <input 
        value={data.name}
        className='border'
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <p className='font-bold'>Телефон<span className='text-red-500'>*</span></p>
      <input 
        value={data.phone}
        className='border'
        onChange={(e) => setData({ ...data, phone: e.target.value })}
      />
      <div></div>
      <button 
        onClick={() => handleCreateLead(data)}
        className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-400'
      >
        Создать
      </button>
    </div>
  );
};

export default CreateLeadModal;
