import { Suspense, memo } from "react"
import { motion } from "framer-motion"

export { Search } from "./search/search";
export { Navigation } from "./nav/Navigation"
export { Icon } from "./icon/Icon"
export { Container } from "./container/Container"
export { Select } from "./select/Select"
export { Button } from "./button/Button"
export { Modal } from "./modal/modal"
export { Card } from "./card/card"
export { ClassHelper } from "./helpers/class"
export { Loader } from "./loader/loader"
export { Animate } from "./animate/Animate"
export { RangeDatePicker } from "./rangedatepicker/RangeDatePicker"
export { ColoredSelect } from "./coloredselect/coloredselect"
export { Back } from "./back/Back"
export { IconButton } from "./iconbutton/IconButton"
export { Paginate } from "./paginate/paginate"
export { Table } from "./table/table"
export { Switch } from "./switch/switch"
export { ReactSelect } from "./reactselect/ReactSelect"
export { VncViewer } from "./vnc/VncViewer"
export { LanguageSwitcher } from "./langswitcher/LangSwitcher"
export { TeamSlider } from "./teamslider/TeamSilder"
export { SurveyButton } from "./surveybtn"
export { TextInput } from "./TextInput"
export { DatePicker } from "./DatePick"
export { CheckBox } from "./CheckBox"
export { EmploymentTable } from "./EmploymentTable"
export { ScrollToTop } from "./scrolltotop/ScrollToTop"
export { IntPhoneInput } from "./multiphoneinput/IntPhoneInput"
export { RadioGroup } from "./radiogroup/RadioGroup"

export const s = (Component) => {
  const WrappedComponent = memo((props) => (
    <Suspense
      fallback={
        <div className="flex items-center justify-center h-screen bg-[#f0f4f8]">
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{
              duration: 0.6,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            <motion.div
              className="w-10 h-10 bg-gradient-to-r from-[#84baf8] to-[#e23bb8] rounded-full"
              animate={{
                scale: [1, 1.3, 1],
                rotate: [0, 180, 360],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "loop",
              }}
            ></motion.div>
            <motion.div
              className="mt-4 text-lg font-semibold text-[#2f34c0]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              Talk&Call
            </motion.div>
          </motion.div>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  ))
  WrappedComponent.displayName = `withSuspense(${
    Component.displayName || Component.name
  })`
  return WrappedComponent
}
