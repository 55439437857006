import React from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export function IntPhoneInput({
  value,
  onChange,
  label,
  required,
  className,
  placeholder = "Ваш номер телефона",
  onlyCountries = ["kz", "uz", "kg"],
  country = "kz", // Default country is Kazakhstan
}) {
  return (
    <div className={`grid gap-1 ${className}`}>
      {label && (
        <div className="flex items-center gap-2">
          <label className="text-md font-semibold text-[#2f34c0]">
            {label}
          </label>
          {required && <span className="text-red-500">*</span>}
        </div>
      )}

      <PhoneInput
        country={country}
        value={value}
        onChange={onChange}
        onlyCountries={onlyCountries}
        inputStyle={{
          width: "100%",
          height: "48px",
          padding: "12px",
          paddingLeft: "50px", // Adjust padding to make space for the flag
          border: "1.5px solid #84baf8",
          color: "#2f34c0",
          fontSize: "16px",
          backgroundColor: "white",
          borderRadius: "0.5rem",
          outline: "0",
          transition: "all 0.3s",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", // Add subtle shadow for focus effect
        }}
        buttonStyle={{
          border: "1.5px solid #84baf8",
          backgroundColor: "#f8f9fa",
        }}
        dropdownStyle={{
          border: "1.5px solid #84baf8",
          backgroundColor: "white",
        }}
        containerClass="phone-input-container"
        inputClass="phone-input"
        placeholder={placeholder}
        required={required}
      />
    </div>
  )
}
