import { Fragment } from "react"
import { Container } from "components/lib"

export function AuthLayout(props) {
  return (
    <Fragment>
      <Container>{<props.children {...props.data} />}</Container>
    </Fragment>
  )
}
