import axios from "axios";
import { createContext, useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ViewContext } from "./view";
import moment from "moment"

const AdminContext = createContext()

export const AdminProvider = ({ children }) => {
  const city = localStorage.getItem("city"); 

  const navigate = useNavigate();
  const context = useContext(ViewContext);

  const [activeTab, setActiveTab] = useState("allLeads")
  const [barChart, setBarChart] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  const [leads, setLeads] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filter, setFilter] = useState({
    start_date: moment().startOf("month"),
    end_date: moment().endOf("month"),
    status: "all",
  });
  const [search, setSearch] = useState(""); 

  useEffect(() => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const city = localStorage.getItem("city");

    if(!username || !password || !city) {
      navigate('/admin');
    }

    fetchStatuses();
  }, []);

  useEffect(() => {
    fetchLeads()
  }, [filter, search])

  const fetchLeads = () => {
    if(!filter?.start_date || !filter?.end_date) return;

    const params = {
      start_date: filter?.start_date?.format("YYYY-MM-DD"),
      end_date: filter?.end_date?.format("YYYY-MM-DD"),
      status: filter?.status,
      city
    };

    if(search !== "") params.search = search

    axios({
      method: 'GET',
      url: `/api/landing/leads`,
      params
    }).then((res) => {
      setLeads(res.data.leads);
      setBarChart(res.data.barChart);
      setPieChart(res.data.pieChart);
    })
  };

  const fetchStatuses = () => {
    axios({
      method: 'GET',
      url: `/api/landing/leads/statuses`,
    }).then((res) => {
      const arr = res.data.statuses.map((status) => ({ 
        value: status.status, label: status.name 
      }))
      setStatuses(arr);
    })
  };

  const handleCreateLead = async (info) => {
    await axios({
      method: 'POST',
      url: '/api/lead',
      data: info
    }).then(() => {
      context.modal.hide();
      fetchLeads();
    })
  };

  const handleUpdate = async (lead_id, data) => {
    await axios({
      method: 'PATCH',
      url: `/api/landing/leads/${lead_id}`,
      data
    })  
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('city');
    navigate("/admin");
  };

  return (
    <AdminContext.Provider 
      value={{
        activeTab,
        setActiveTab,
        barChart,
        pieChart,
        leads,
        setLeads,
        statuses,
        filter,
        setFilter,
        search,
        setSearch,
        handleUpdate,
        handleCreateLead,
        handleLogout,
      }}>
        {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within a AdminProvider');
  }
  return context;
};
