import React, { useContext } from "react"
import { ToastContainer, Slide } from "react-toastify"
import { ViewContext } from "contexts/view"
import "react-toastify/dist/ReactToastify.css"
import "./custom-toast.css" // Custom CSS for additional styling

export default function Toaster() {
  const { mode } = useContext(ViewContext)

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        theme={mode ? "dark" : "light"}
        className="custom-toast-container"
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
        progressClassName="custom-toast-progress"
      />
    </div>
  )
}
