// components
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { View } from "contexts/view"
import axios from "axios"
import Settings from "./data/settings.json"
import NotFound from "views/error"
import { ParallaxProvider } from "react-scroll-parallax"
import routes from "routes/lib"

export default function App(props) {
  axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <View
                    display={route.view}
                    layout={route.layout}
                    title={route.title}
                  />
                }
              />
            )
          })}

          {/* 404 */}
          <Route
            path="*"
            element={
              <View
                display={NotFound}
                layout="home"
                title={"404 Страница не найдена"}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  )
}
