import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { Icon } from "components/lib" // Assuming you have an Icon component for rendering icons
import { useTranslation } from "react-i18next"

export const Back = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <motion.button
      className="text-[#2f34c0] cursor-pointer bg-white px-4 py-2 rounded-lg shadow-lg hover:bg-[#E0C3FC] hover:text-[#2f34c0] transition duration-300 w-full"
      onClick={() => navigate(-1)}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.1 }}
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex items-center gap-2">
        <Icon image="arrow-left" size={24} />
        <span className="text-lg font-semibold">{t("back")}</span>
      </div>
    </motion.button>
  )
}
