import { ViewContext } from "contexts/view";
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AdminProvider, useAdmin } from "contexts/admin";
import Header from "./admin/header";
import Sidebar from "./admin/sidebar";

export function AdminLayout(props) {
  const context = useContext(ViewContext);

  return (
    <AdminProvider>
      <div className="flex flex-col h-screen">
        <Header />
        <div className="flex flex-1 pt-20">
          <Sidebar />
          <main className="flex-1 ml-64 p-6 overflow-y-auto h-screen">
            {<props.children {...props.data} />}
          </main>
        </div>
      </div>
    </AdminProvider>
  );
};


