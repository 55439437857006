import React from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import teamMember1 from "../../assets/three.webp"
import teamMember2 from "../../assets/four.webp"
import teamMember3 from "../../assets/five.webp"
import { useTranslation } from "react-i18next"

const teamMembers = [
  { id: 1, name: "managers", role: "hr", image: teamMember1 },
  { id: 2, name: "topsellers", role: "selldepartment", image: teamMember2 },
  { id: 3, name: "Talk&Call", role: "employees", image: teamMember3 },
]

// Custom Next Arrow
const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#e23bb8] p-3 rounded-full cursor-pointer shadow-lg z-10"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  )
}

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#e23bb8] p-3 rounded-full cursor-pointer shadow-lg z-10"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </div>
  )
}

export function TeamSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval (3 seconds)
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const { t } = useTranslation()

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <section id="team" className="py-20 px-4 bg-gray-100 text-center">
        <h2 className="text-4xl font-semibold text-[#e23bb8] mb-12">
          {t("ourteam")}
        </h2>
        <Slider {...settings}>
          {teamMembers.map((member) => (
            <motion.div
              key={member.id}
              className="p-4"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.5 }}
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-2xl font-semibold text-gray-800">
                    {t(member.name)}
                  </h3>
                  <p className="text-gray-600">{t(member.role)}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </Slider>
      </section>
    </motion.div>
  )
}
