import React from 'react'
import { Icon } from "components/lib"
import { useAdmin } from 'contexts/admin';

const Header = (props) => {
  const admin = useAdmin();
  
  return (
    <header className="bg-white p-4 flex justify-between items-center w-full shadow-sm fixed top-0 z-10">
      <div className="flex items-center gap-2">
        <Icon image="settings" />
        <h1 className="text-xl font-semibold text-gray-800">
          Talk&Call HR Admin
        </h1>
      </div>

      <div className="flex items-center gap-2">
        <div 
          onClick={() => admin.handleLogout()}
          className="px-4 py-1 bg-gray-200 rounded-full hover:bg-red-400 hover:text-white cursor-pointer"
        >
          Выйти
        </div>
        <div className="bg-gray-200 p-2 rounded-full hover:bg-indigo-400 hover:text-white cursor-pointer">
          <Icon image="settings" />
        </div>
        <div className="bg-gray-200 p-2 rounded-full hover:bg-indigo-400 hover:text-white cursor-pointer">
          <Icon image="bell" />
        </div>
        <div className="cursor-pointer">
          <img
            src="/ava.jpg"
            alt="avatar"
            className="w-10 h-10 rounded-full object-center"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
